<template>
  <div>
    <b-modal size="xl" class="modal-size" hide-footer v-model="modal_client" title="NUEVO CLIENTE">
      <b-row>
        <b-col md="12">
           <b-form id="Form" @submit.prevent="Validate">
              <b-row>
                      <b-col md="3">
                        <b-form-group label="Tipo de Documento :">
                          <b-form-select v-model="client.document_type" :options="document_type"></b-form-select>
                        </b-form-group>
                        <small v-if="errors.document_type"  class="form-text text-danger" >Seleccione un tipo de documento</small>
                      </b-col>

                      <b-col md="3">
                        <b-form-group label="Nro Documento :">
                          <b-input-group>
                            <b-form-input v-model="client.document_number" class="form-control" ></b-form-input>
                            <b-input-group-append>
                              <b-button variant="primary"  @click="SearchClient"><b-icon icon="search"></b-icon></b-button>
                            </b-input-group-append>
                          </b-input-group>
                          <small v-if="errors.document_number" class="form-text text-danger" >{{error_document_number}}</small>
                        </b-form-group>
                      </b-col>
                      <b-col md="6">
                        <b-form-group label="Nombres:" required>
                          <b-form-input type="text" v-model="client.name"></b-form-input>
                          <small v-if="errors.name" class="form-text text-danger" >Ingrese un nombre o razón social</small>
                        </b-form-group>
                      </b-col>

                      <b-col md="2">
                        <b-form-group label="Pais :">
                        <b-form-select placeholder="Seleccione un pais" label="text" class="w-100"  v-model="client.country" :options="countries"></b-form-select>
                        </b-form-group>
                      </b-col>

                      <b-col md="3">
                        <b-form-group label="Dirección :">
                          <b-form-input type="text" v-model="client.address"></b-form-input>
                        </b-form-group>
                      </b-col>

                      <b-col md="3">
                        <b-form-group label="Email :">
                          <b-form-input type="text" v-model="client.email"></b-form-input>
                        </b-form-group>
                      </b-col>

                      <b-col md="2">
                        <b-form-group label="Telefono :">
                          <b-form-input type="text" v-model="client.phone"></b-form-input>
                        </b-form-group>
                      </b-col>

                      <b-col md="2">
                        <b-form-group label="Estado :">
                          <b-form-select :options="state" v-model="client.state"></b-form-select>
                        </b-form-group>
                      </b-col>

              
                      <b-col md="5"></b-col>
                      <b-col md="2">
                        <b-button type="submit" class="form-control" variant="primary" ><i class="fas fa-save"></i> Guardar (F4)</b-button>
                      </b-col>
                    </b-row>
            
            </b-form>
        </b-col>
  
      </b-row>
    </b-modal>
     <LoadingComponent :is-visible="isLoading"/>
    <Keypress key-event="keyup" :key-code="115" @success="Validate" />
  </div>
</template>

<style>


</style>

<script>

import vSelect from "vue-select";
import 'vue-select/dist/vue-select.css';
import "vue-select/src/scss/vue-select.scss";

const axios = require("axios").default;
const Swal = require("sweetalert2");
const je = require("json-encrypt");
import { mapState } from "vuex";
import EventBus from "@/assets/js/EventBus";
import ApiQuery from "@/assets/js/APIQuery";
import LoadingComponent from './../pages/Loading'
var moment = require("moment");
import CodeToName from "@/assets/js/CodeToName";

export default {
  name: "ModalClient",
  components:{
       vSelect,
    Keypress: () => import('vue-keypress'),
      LoadingComponent,
  },
  data() {
   return {
     modal_client:false,
      isLoading: false,
      module:'Client',
      client: {
	      id_client:'',
	      document_type:'1',
	      document_number:'',
	      name:'',
	      country:'PE',
        address:'',
	      email:'',
	      phone:'',
	      state:'1',
      },
      countries: [],
      document_type: [
        {value: 1 , text : 'DNI'},
        {value: 6 , text : 'RUC'},
        {value: 4 , text : 'CARNET DE EXTRANJERIA'},
        {value: 7 , text : 'PASAPORTE'},
        {value: 0 , text : 'OTROS'},
      ],                      
      state:[
        {value: 1 , text : 'Activo'},
        {value: 0 , text : 'Inactivo'},
      ],
      //errors
      errors: {
        document_type: false,
        document_number: false,
        name: false,
      },
      error_document_number :'',
      validate: false,
      error_document_number_family :'',
      validate_family: false,
    };
  },
  created (){

  },
  mounted () {
    EventBus.$on('ModalClientsShow', () => {
      this.modal_client = true;
    });
    this.LisCountry();
  },
  methods: {
    SearchClient,
    AddClient,
    LisCountry,
    Validate,
  },
  computed: {
    ...mapState(["url_base"]),
    token: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user.api_token;
    }
  },
};

function LisCountry() {
 this.countries = [];
  let url = this.url_base + "list-countries";
  let me = this;
  axios({
      method: "GET",
      url: url,
    }).then(function (response) {
      for (let index = 0; index < response.data.length; index++) {
      const element = response.data[index];
      me.countries.push({value:element.code,text:element.name})
    }
    })

}


function SearchClient() {

  let me = this;
  if (me.client.document_type == 1) {
      if (me.client.document_number.length == 8) {
        me.errors.document_number = false;
        ApiQuery.SearchDni(me.client.document_number).then((data) => {
          if (data.status == 200) {
            me.client.name = data.razon_social;
            me.client.address = data.direccion;
          }else{
            me.client.name = '';
            me.client.address = '';
          }
            
        });
      }else{
        me.errors.document_number = true;
        me.error_document_number = 'El nro de ducumento debe contener 8 digitos';
      }
  }

  if (me.client.document_type == 6) {
      if (me.client.document_number.length == 11) {
        me.errors.document_number = false;
        ApiQuery.SearchRuc(me.client.document_number).then((data) => {
          if (data.status == 200) {
            me.client.name = data.razon_social;
            me.client.address = data.direccion;
          }else{
            me.client.name = '';
            me.client.address = '';
          } 
        });
      }else{
        me.errors.document_number = true;
        me.error_document_number = 'El nro de ducumento debe contener 11 digitos';
      }
  }
  
}

function AddClient() {
  let me = this;
  let url = me.url_base + "client/add";
  let data = new FormData();
  data.append("id_client", this.client.id_client);
  data.append("document_type", this.client.document_type);
  data.append("document_number", this.client.document_number);
  data.append("name", this.client.name);
  data.append("address", this.client.address);
  data.append("country", this.client.country);
  data.append("phone", this.client.phone);
  data.append("email", this.client.email);
  data.append("state", this.client.state);
  me.isLoading = true;
  axios({
    method: "POST",
    url: url,
    data: data,
    headers: { "Content-Type": "application/json", token: me.token, module: me.module, role: 2},
  })
    .then(function (response) {
      if (response.data.status == 201) {
        me.client.id_client = '';
	      me.client.document_type = '1';
	      me.client.document_number = '';
	      me.client.name = '';
        me.client.address = '';
	      me.client.country = 'PE';
	      me.client.phone = '';
	      me.client.email = '';
	      me.client.state = '1';

        let data = {
          id: response.data.result.id_client,
          full_name: response.data.result.name+" - "+response.data.result.document_number,
        };
        EventBus.$emit('ClientInformation',data);

        Swal.fire({ icon: 'success', text: response.data.message, timer: 3000,})
      } else {
        Swal.fire({ icon: 'error', text: response.data.message, timer: 3000,})
      }
      me.isLoading = false;
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      me.isLoading = false;
    });
}

function Validate() {
  this.validate = false;
  if (this.client.document_type == 1) {
    this.errors.document_number = this.client.document_number.length != 8 ? true : false;
    this.error_document_number = 'El nro de ducumento debe contener 8 digitos';
  }else if(this.client.document_type == 6){
    this.errors.document_number = this.client.document_number.length != 11 ? true : false;
    this.error_document_number = 'El nro de ducumento debe contener 11 digitos';
  }else if(this.client.document_type == 4){
    this.errors.document_number = this.client.document_number.length != 12 ? true : false;
    this.error_document_number = 'El nro de ducumento debe contener 12 digitos';
  }else if(this.client.document_type == 7){
    this.errors.document_number = this.client.document_number.length != 12 ? true : false;
    this.error_document_number = 'El nro de ducumento debe contener 12 digitos';
  }else{
    this.errors.document_number = this.client.document_number.length == 0 ? true : false;
    this.error_document_number = 'Ingrese un nro de documento';
  }

  this.errors.document_type = this.client.document_type.length == 0 ? true : false;
  this.errors.name = this.client.name.length == 0 ? true : false;

  if (this.errors.document_type) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate = false; }
  if (this.errors.document_number) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate = false; }
  if (this.errors.name) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate = false; }


  let me = this;

  if (!this.validate) {
    Swal.fire({
      title: "Esta seguro de registrar el cliente?",
      text: "",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Estoy de acuerdo!",
    }).then((result) => {
      if (result.value) {
        this.AddClient();
      }
    });

  }

}






function SearchClientFamily() {

  let me = this;
  if (me.partner_family.document_type == 1) {
      if (me.partner_family.document_number.length == 8) {
        me.errors.document_number = false;
        ApiQuery.SearchDni(me.partner_family.document_number).then((data) => {
          if (data.status == 200) {
            me.partner_family.name = data.razon_social;
            me.partner_family.address = data.direccion;
          }else{
            me.partner_family.name = '';
            me.partner_family.address = '';
          }
            
        });
      }else{
        me.errors.document_number = true;
        me.error_document_number = 'El nro de ducumento debe contener 8 digitos';
      }
  }

  if (me.partner_family.document_type == 6) {
      if (me.partner_family.document_number.length == 11) {
        me.errors.document_number = false;
        ApiQuery.SearchRuc(me.partner_family.document_number).then((data) => {
          if (data.status == 200) {
            me.partner_family.name = data.razon_social;
            me.partner_family.address = data.direccion;
          }else{
            me.partner_family.name = '';
            me.partner_family.address = '';
          } 
        });
      }else{
        me.errors.document_number = true;
        me.error_document_number = 'El nro de ducumento debe contener 11 digitos';
      }
  }
  
}

function ValidateFamily() {


  this.validate = false;
  if (this.partner_family.document_type == 1) {
    this.errors.document_number = this.partner_family.document_number.length != 8 ? true : false;
    this.error_document_number_family = 'El nro de ducumento debe contener 8 digitos';
  }else if(this.partner_family.document_type == 6){
    this.errors.document_number = this.partner_family.document_number.length != 11 ? true : false;
    this.error_document_number_family = 'El nro de ducumento debe contener 11 digitos';
  }else if(this.partner_family.document_type == 4){
    this.errors.document_number = this.partner_family.document_number.length != 12 ? true : false;
    this.error_document_number_family = 'El nro de ducumento debe contener 12 digitos';
  }else if(this.partner_family.document_type == 7){
    this.errors.document_number = this.partner_family.document_number.length != 12 ? true : false;
    this.error_document_number_family = 'El nro de ducumento debe contener 12 digitos';
  }else{
    this.errors.document_number = this.partner_family.document_number.length == 0 ? true : false;
    this.error_document_number_family = 'Ingrese un nro de documento';
  }

  this.errors.document_type_family = this.partner_family.document_type.length == 0 ? true : false;
  this.errors.document_number_family = this.partner_family.document_number.length == 0 ? true : false;
  this.errors.name_family = this.partner_family.name.length == 0 ? true : false;
  this.errors.relationship_family = this.partner_family.relationship.length == 0 ? true : false;

  if (this.errors.document_type_family) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate = false; }
  if (this.errors.document_number_family) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate = false; }
  if (this.errors.name_family) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate = false; }
  if (this.errors.relationship_family) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate = false; }


  this.client.partners_family.push({
    id_client_family:this.partner_family.id_client_family,
    document_type:this.partner_family.document_type,
    document_number:this.partner_family.document_number,
    name:this.partner_family.name,
    phone:this.partner_family.phone,
    cell_phone:this.partner_family.cell_phone,
    type_beneficiary:this.partner_family.type_beneficiary,
    relationship:this.partner_family.relationship,
    state:this.partner_family.state,
  });
  this.partner_family.id_client_family = '';
  this.partner_family.document_type = '1';
  this.partner_family.document_number = '';
  this.partner_family.name = '';
  this.partner_family.phone = '';
  this.partner_family.cell_phone = '';
  this.partner_family.type_beneficiary = '2';
  this.partner_family.relationship = '';
  this.partner_family.state = '1';

}

function DeleteBeneficiary(index) {
  this.client.partners_family.splice(index, 1);
}
</script>
